import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchStudents(ctx, params) {
      return useJwt.getStudents(params).then(response => response);
    },
    exportStudents(ctx, params) {
      return useJwt.exportStudents(params).then(response => response);
    },
    generateImportStudentTemplate(ctx, params) {
      return useJwt.generateImportStudentTemplate(params).then(response => response);
    },
    createStudent(ctx, data) {
      return useJwt.createStudent(data).then(response => response);
    },
    updateStudent(ctx, data) {
      return useJwt.updateStudent(data).then(response => response);
    },
    importStudents(ctx, data) {
      return useJwt.importStudents(data).then(response => response);
    },
    getStudentDetail(ctx, id) {
      return useJwt.getStudentDetail(id).then(response => response);
    },
    deleteStudents(ctx, data) {
      return useJwt.deleteStudents(data).then(response => response);
    },
    fetchStudentContactZalos(ctx, studentId) {
      return useJwt.getStudentContactZalos(studentId).then(response => response);
    },
    deleteStudentContactZalos(ctx, data) {
      return useJwt.deleteStudentContactZalos(data).then(response => response);
    },
  },
};
