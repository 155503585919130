import { computed, ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { parseDateToString } from '@/auth/utils';
import studentStoreModule from '../studentStoreModule';
export default function useZaloAccountModal(props, emit) {
  const STORE_MODULE_NAME = 'student';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, studentStoreModule);
  }

  const toastification = toast();
  const isSubmitting = ref(false);
  const refModal = ref(null);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    isSubmitting.value = false;
  };
  const rows = ref([]);
  const isLoading = ref(false)
  const item = computed(() => props.item)


  const fetchData = () => {
    isLoading.value = true
    store.dispatch('student/fetchStudentContactZalos', { studentId: item.value.id }).then(response => {
      isLoading.value = false
      rows.value = response.data
    }).catch(error => {
      toastification.showToastError(error)
      isLoading.value = false
    })
  }

  const resetModal = () => {
    fetchData()
  };

  const deleteZaloAccount = (deleteItem) => {
    store.dispatch('student/deleteStudentContactZalos', {
      ids: [deleteItem.id],
    }).then(response => {
      fetchData()
      emit('refetch-data')
    }).catch(error => {
      toastification.showToastError(error)
    })
  }


  const columns = [
    {
      label: 'TK Zalo',
      field: 'zaloAccount',
    },
    {
      label: 'Họ tên',
      field: 'name',
    },
    {
      label: 'Số điện thoại',
      field: 'phone',
    },
    {
      label: 'Địa chỉ',
      field: 'fullAddress',
    },
    {
      label: 'Thao tác',
      field: 'action',
    },

  ];


  return {
    refModal,
    itemLocal,
    resetItemLocal,
    resetModal,
    columns,
    rows,
    isLoading,
    deleteZaloAccount
  };
}
