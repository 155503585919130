<template>
  <b-modal
    id="modal-zalo-account"
    ref="refModal"
    title="Tài khoản Zalo liên kết"
    size="lg"
    :hide-header-close="false"
    hide-footer
    @show="resetModal"
    @hidden="resetModal"
  >
    <!-- Body -->
    <b-row class="mt-2">
      <b-col cols="12">
        <vue-good-table
          style-class="vgt-table striped bordered"
          mode="remote"
          :total-rows="rows.length"
          :is-loading.sync="isLoading"
          :columns="columns"
          :rows="rows"
        >
          <template slot="loadingContent">
            <b-spinner label="Loading" type="grow" />
          </template>
          <template slot="table-row" slot-scope="props">
            <!-- Column: Code -->
            <span v-if="props.column.field === 'zaloAccount'">
              <b-avatar size="sm" :src="props.row.zaloAvatar" />
              <span class="ml-50">{{ props.row.zaloDisplayName }}</span>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <table-actions
                name="tài khoản zalo liên kết"
                :hide-delete="false"
                :hide-edit="true"
                @on-delete="deleteZaloAccount(props.row)"
              />
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props"> </template>
          <div slot="emptystate">
            <div class="text-center text-muted">Không có bản ghi nào!</div>
          </div>
        </vue-good-table>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormCheckbox,
  BSpinner,
  BAvatar,
} from "bootstrap-vue";
import ImageView from "@/views/components/ImageView.vue";
import useZaloAccountModal from "./useZaloAccountModal";
import { VueGoodTable } from "vue-good-table";
import TableActions from "@/views/components/TableActions.vue";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    ImageView,
    BSpinner,
    VueGoodTable,
    BAvatar,
    TableActions,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      columns,
      rows,
      isLoading,
      deleteZaloAccount,
    } = useZaloAccountModal(props, emit);

    return {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      columns,
      rows,
      isLoading,
      deleteZaloAccount,
    };
  },
};
</script>
